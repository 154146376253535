import styled from 'styled-components';
import { ReactComponent as LinkIcon } from '../images/link.svg';

const StyledCard = styled.a`
  .flip-card {
    width: 300px;
    height: 300px;
    perspective: 1000px;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .flip-card-inner {
    position: relative;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card .flip-card-inner {
    @media only screen and (max-width: 820px) {
      transform: rotateY(180deg);
    }
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    color: black;
  }

  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
  }
`;

export function Card({
  title,
  children,
  img,
  alt,
  className,
  imgClassName,
  //   imageBack,
  //   imgBackClassName,
  href,
  tools,
}) {
  return (
    <StyledCard href={href} target="_blank" rel="noopener noreferrer" className="rounded-2xl">
      <div className="flip-card rounded-2xl sm:h-[300px] sm:w-[300px] md:min-h-[300px] md:min-w-[300px]">
        <div className="flip-card-inner">
          <div className={`${className} flip-card-front !bg-[#F8B2D8]`}>
            <img src={img} alt={alt} className={imgClassName} />
          </div>
          <div className="flip-card-back flex items-center justify-center overflow-hidden rounded-2xl bg-[#F8B2D8]">
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute top-4 right-4"
            >
              <LinkIcon className="h-5 w-5 text-[#112240]" />
            </a>
            <div className="flex h-full w-full flex-col justify-between py-8 px-7 text-left">
              <h1 className="pb-4 text-xl font-bold text-[#E4F0FF]">{title}</h1>
              <p className="text-base leading-5 text-[#E4F0FF]">{children}</p>
              <p className="pt-4 text-xs">{tools}</p>
            </div>
            {/* <img src={imageBack} alt={alt} className={`${imgBackClassName} -z-10`} /> */}
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
