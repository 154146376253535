import { Link, animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';
import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Se } from '../images/se.svg';
import { ReactComponent as Uk } from '../images/uk.svg';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { Context } from './Wrapper';
import Pdf from '../pdf/cv.pdf';

const StyledHeader = styled.header`
  z-index: 90;
  padding: 30px;
  background-color: #0b1930;
  top: 0;
  right: 0;
  position: fixed;

  .dropButton {
    color: white;
    border: none;
  }

  .dropdown {
    position: relative;

    .en,
    .sv {
      height: 46px;
      width: 33px;
    }

    button {
      svg {
        width: 100%;
        height: auto;
        pointer-events: none;
      }
    }

    &:hover .dropContent {
      display: block;
      opacity: 1;
      transition-duration: 400ms;
      pointer-events: auto;
    }
  }

  .dropContent {
    opacity: 0;
    position: absolute;
    width: 100%;
    transition-duration: 400ms;
    pointer-events: none;
  }

  .show {
    top: 0;
    transition: top 0.4s ease-out;
    background: red !important;
  }

  .hide {
    top: -10px;
    transition: top 0.4s ease-out;
  }
`;

export function Header(): JSX.Element {
  const context = useContext(Context);
  const { selectLanguage } = context;

  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const cls = visible
    ? 'md:transition md:duration-2000 md:ease-in-out flex w-full justify-end opacity-100'
    : 'md:transition md:duration-2000 md:ease-in-out opacity-0';

  return (
    <StyledHeader className={`${cls}`}>
      <ul className="flex w-96 items-center justify-evenly">
        <li>
          <div className="dropdown">
            <button
              type="button"
              className="dropButton !h-auto !w-8 transition duration-200 ease-in-out hover:cursor-pointer hover:!text-[#F8B2D8]"
            >
              <GlobeAltIcon className="mr-8 mt-2 !h-auto" />
            </button>
            <div className="dropContent">
              <button type="button" className="en" onClick={selectLanguage} value="en">
                <Uk className="!h-auto !w-8" />
              </button>
              <button type="button" className="sv" onClick={selectLanguage} value="sv">
                <Se className="!h-auto !w-8" />
              </button>
            </div>
          </div>
        </li>
        <li className="text-white transition duration-200 ease-in-out hover:cursor-pointer hover:text-[#F8B2D8]">
          <Link
            className="w-44"
            to="about"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={300}
          >
            <p className="text-base">
              <FormattedMessage id="header.about" />
            </p>
          </Link>
        </li>
        <li className="text-white transition duration-200 ease-in-out hover:cursor-pointer hover:text-[#F8B2D8]">
          <Link
            className="w-44"
            to="work"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={300}
          >
            <p className="text-base">
              <FormattedMessage id="header.work" />
            </p>
          </Link>
        </li>
        <li className="hidden text-white transition duration-200 ease-in-out hover:cursor-pointer hover:text-[#F8B2D8] md:block">
          <Link
            className="w-44"
            to="footer"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={300}
          >
            <p className="text-base">
              <FormattedMessage id="header.contact" />
            </p>
          </Link>
        </li>
        <li>
          <a
            href={Pdf}
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-md border py-2 px-3 text-base text-white transition duration-200 ease-in-out hover:cursor-pointer hover:border-[#F8B2D8] hover:text-[#F8B2D8]"
          >
            <FormattedMessage id="header.buttonText" />
          </a>
        </li>
      </ul>
    </StyledHeader>
  );
}
