import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import English from '../lang/en.json';
import Swedish from '../lang/se.json';

export const Context = React.createContext();

const local = navigator.language;

let lang;
if (local === 'sv') {
  lang = Swedish;
} else {
  lang = English;
}

const Wrapper = props => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  const { children } = props;

  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === 'sv') {
      setMessages(Swedish);
    } else {
      setMessages(English);
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Wrapper;
