import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import { Link, animateScroll as scroll } from 'react-scroll';
import { FormattedMessage } from 'react-intl';

export function Hero() {
  return (
    <section className="my-0 mx-auto max-w-5xl justify-center xl:min-w-full">
      <div className="mx-auto flex min-h-screen max-w-5xl flex-col justify-center pb-8 pt-7 text-left">
        <p className="mb-4 text-left text-3xl text-[#8a99bf] md:pb-7 md:text-4xl">
          Front-End Developer
        </p>
        <h1 className="pb-2 text-5xl text-white md:text-8xl">
          <span className="text-[#F8B2D8]">Joel </span>
          <span>Greek</span>
        </h1>
        {/* <p className="text-left text-2xl text-[#8a99bf]">
          <FormattedMessage id="hero.work" />
        </p> */}
        <Link
          className="w-44"
          to="about"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={300}
        >
          <ArrowDownCircleIcon className="my-8 w-28 text-white transition duration-200 ease-in-out md:w-44 md:hover:-translate-y-1 md:hover:cursor-pointer md:hover:text-[#F8B2D8]" />
        </Link>
      </div>
    </section>
  );
}
