import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';

export function SideIcons() {
  return (
    <div className="fixed bottom-20 right-auto left-10 z-10 hidden w-10 md:flex">
      <ul className="m-0 flex list-none flex-col items-center p-0">
        <li className="list-item">
          <a
            href="https://www.linkedin.com/in/joelgreek/"
            target="_blank"
            rel="noopener noreferrer"
            className="relative flex p-3 !text-[#0b1930] transition duration-200 ease-in-out hover:-translate-y-1 hover:!text-[#F8B2D8]"
          >
            <LinkedInIcon className="!h-5 !w-5 bg-white" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/greekjoel"
            target="_blank"
            rel="noopener noreferrer"
            className="relative flex p-3 !text-[#0b1930] transition duration-200 ease-in-out hover:-translate-y-1 hover:!text-[#F8B2D8]"
          >
            <TwitterIcon className="!h-5 !w-5 bg-white" />
          </a>
        </li>
        <li className="list-item">
          <a
            href="mailto:joelgreek@gmail.com"
            className="relative flex p-3 text-sm text-white transition duration-200 ease-in-out vertical-rl hover:-translate-y-1 hover:!text-[#F8B2D8]"
          >
            joelgreek@gmail.com
          </a>
        </li>
      </ul>
    </div>
  );
}
