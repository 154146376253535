import './App.css';
import { Work } from './components/Work';
import { About } from './components/About';
import { Hero } from './components/Hero';
import { Header } from './components/Header';
import { SideIcons } from './components/SideIcons';
import { Footer } from './components/Footer';

function App() {
  return (
    <section className="!bg-[#0b1930] px-8">
      <Header />
      <div className="content pb-32">
        <Hero />
        <About />
        <SideIcons />
        <Work />
      </div>
      <Footer />
    </section>
  );
}

export default App;
