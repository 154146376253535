import { Card } from './Card';
import spin from '../images/spin.png';
import spinaff from '../images/spinaff.png';
import lucky from '../images/lucky.png';
import luckyaff from '../images/luckyaff.png';
import magic from '../images/magic.svg';
import glomo from '../images/glomo.svg';
import wonder from '../images/wonder.svg';
import { FormattedMessage } from 'react-intl';

export function Work() {
  return (
    <section className="flex max-w-5xl justify-center pb-8 md:mb-40" id="work">
      <div className="bg-[#0b1930] pb-8 pt-7 text-left text-white">
        <h1 className="my-auto mr-auto ml-0 pb-8 text-4xl">
          <FormattedMessage id="work.header" />
        </h1>
        <div className="card-wrapper grid grid-cols-1 gap-5 lg:grid-cols-2 2xl:grid-cols-3">
          <Card
            title="Magic Jackpot"
            className="flex items-center justify-center rounded-2xl bg-[#112240]"
            imgClassName="w-52 h-auto"
            alt="magicjackpot"
            img={magic}
            // imageBack={magic}
            // imgBackClassName="w-52 h-auto absolute opacity-10 -scale-x-100"
            href="https://www.magicjackpot.ro"
            tools="React Redux TypeScript Tailwind Styled Components"
          >
            <FormattedMessage id="magic.card.text" />
          </Card>
          <Card
            title="SpinAway Partners"
            className="flex items-center justify-center rounded-2xl bg-[#112240]"
            imgClassName="w-52 h-auto"
            alt="spinaway"
            img={spinaff}
            // imageBack={spinaff}
            // imgBackClassName="w-52 h-auto !absolute opacity-10 -scale-x-100"
            href="https://www.spinawaypartners.com"
            tools="React Redux TypeScript Tailwind Styled Components"
          >
            <FormattedMessage id="spinaff.card.text" />
          </Card>
          <Card
            title="LuckyDays Affiliate site"
            className="flex items-center justify-center overflow-hidden rounded-2xl bg-[#112240]"
            imgClassName="w-64 h-auto top-[185px] md:top-[287px]"
            alt={luckyaff}
            img={luckyaff}
            // imageBack={luckyaff}
            // imgBackClassName="!absolute w-64 h-auto opacity-10 -scale-x-100 "
            href="https://www.luckydaysaffiliates.com"
            tools="Gatsby.js Styled Components"
          >
            <FormattedMessage id="luckyaff.card.text" />
          </Card>
          <Card
            title="SpinAway"
            className="flex items-center justify-center rounded-2xl bg-[#112240]"
            imgClassName="w-36 h-auto !absolute"
            alt="spinaway"
            img={spin}
            // imageBack={spin}
            // imgBackClassName="w-36 h-auto !absolute opacity-10 -scale-x-100"
            href="https://www.spinaway.com"
            tools="React Redux TypeScript Tailwind Styled Components"
          >
            <FormattedMessage id="spin.card.text" />
          </Card>
          <Card
            title="LuckyDays"
            className="flex justify-center overflow-hidden rounded-2xl bg-[#112240]"
            imgClassName="!absolute w-52 top-[185px] md:top-[186px]"
            alt={lucky}
            img={lucky}
            // imageBack={lucky}
            // imgBackClassName="!absolute w-52 top-[185px] md:top-[186px] opacity-10 -scale-x-100 "
            href="https://www.luckydays.com"
            tools="React Redux TypeScript Tailwind Styled Components"
          >
            <FormattedMessage id="lucky.card.text" />
          </Card>
          <Card
            title="Wonderfour"
            className="flex items-center justify-center rounded-2xl bg-[#112240]"
            imgClassName="w-52 h-auto"
            alt="wonderfour"
            img={wonder}
            // imageBack={klartankt}
            // imgBackClassName="w-52 h-auto absolute opacity-10"
            href="https://klartankt.nu/"
            tools="PHP jQuery Wordpress CSS"
          >
            <FormattedMessage id="wonderfour.card.text" />
          </Card>
          <Card
            title="Glomo"
            className="flex items-center justify-center rounded-2xl bg-[#112240]"
            imgClassName="w-52 h-auto"
            alt="glomo"
            img={glomo}
            // imageBack={glomo}
            // imgBackClassName="w-52 h-auto absolute opacity-10 -scale-x-100"
            href="https://www.glomo.se"
            tools="React Redux SASS/SCSS Zeplin"
          >
            <FormattedMessage id="glomo.card.text" />
          </Card>
        </div>
      </div>
    </section>
  );
}
