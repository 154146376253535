import { EnvelopeIcon } from '@heroicons/react/24/outline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { FormattedMessage } from 'react-intl';

export function Footer() {
  return (
    <footer
      id="footer"
      className="fixed right-0 bottom-0 left-0 flex w-full flex-col items-center justify-center bg-[#0b1930] text-center shadow sm:bg-transparent md:relative md:flex md:h-32 md:items-center md:pb-4 md:pt-2"
    >
      <h1 className="mb-5 hidden text-3xl text-white md:flex md:text-4xl">
        <FormattedMessage id="footer.header" />
      </h1>
      <p className="hidden w-96 text-[#F8B2D8] md:mb-12 md:flex">
        <FormattedMessage id="footer.text" />
      </p>
      <ul className="mt-3 mb-4 flex flex-wrap items-center text-sm text-gray-500 dark:text-gray-400 sm:mt-0 md:mb-60">
        <li className="mx-2 list-item md:mx-0">
          <a target="_blank" rel="noopener noreferrer" href="mailto:joelgreek@gmail.com">
            <EnvelopeIcon className="h-auto w-16 px-3 py-0 text-white md:transition md:duration-200 md:ease-in-out md:hover:-translate-y-1 md:hover:fill-[#F8B2D8]" />
          </a>
        </li>
        <li className="mx-2 list-item md:mx-0">
          <a
            href="https://www.linkedin.com/in/joelgreek/"
            target="_blank"
            rel="noopener noreferrer"
            className="relative flex px-3 text-[#222d48] md:transition md:duration-200 md:ease-in-out md:hover:-translate-y-1 md:hover:text-[#F8B2D8]"
          >
            <LinkedInIcon className="!h-8 !w-8 rounded-md bg-white" />
          </a>
        </li>
        <li className="mx-2 list-item md:mx-0">
          <a
            href="https://twitter.com/greekjoel"
            target="_blank"
            rel="noopener noreferrer"
            className="relative flex px-3 text-[#222d48] md:transition md:duration-200 md:ease-in-out md:hover:-translate-y-1 md:hover:text-[#F8B2D8]"
          >
            <TwitterIcon className="!h-8 !w-8 rounded-md bg-white" />
          </a>
        </li>
      </ul>
    </footer>
  );
}
