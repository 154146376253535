import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import Pdf from '../pdf/cv.pdf';
import React, { useContext } from 'react';
import { Context } from './Wrapper';
import me from '../images/me.png';

export function About() {
  const context = useContext(Context);
  const { selectLanguage } = context;
  return (
    <section
      id="about"
      className="mb-10 flex max-w-2xl flex-col items-center justify-around md:mb-36 2xl:flex-row "
    >
      <div className="!min-w-full text-left">
        <h1 className="pb-8 text-4xl text-[#5b698c]">
          <span className="text-[#F8B2D8]">
            <FormattedMessage id="about.header1" />
          </span>
          <span className="text-white">
            {' '}
            <FormattedMessage id="about.header2" />
          </span>
          <br />
        </h1>
        <div>
          <p className="mb-8 max-w-lg text-left text-base text-[#8a99bf] sm:text-xl md:text-lg">
            <FormattedMessage
              id="about.text"
              defaultMessage={`${
                context.locale === 'sv'
                  ? 'Jag studerade Front-End utveckling på Nackademin i Stockholm. Sen dess har jag frilansat för ett online-casino där jag hjälpt till att bygga tre casino-siter samt ett par affiliate-siter. Läs mer om mig och om min erfarenhet i mitt {link}. De teknologier och verktyg jag främst använt är:'
                  : "I studied Front-End development at Nackademin in Stockholm. Since then I've been working at a casino company where I've helped creating three different brands and a couple of affiliate sites. I also enjoy making music. You can read more about me and my job experience in my {link}. The main technologies and tools I have used are:"
              }`}
              values={{
                link: (
                  <a
                    href={Pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border-b-[#F8B2D8] !text-[#F8B2D8] hover:border-b"
                  >
                    <FormattedMessage
                      id="link"
                      defaultMessage={`${context.locale === 'sv' ? 'CV' : 'resume'}`}
                    />
                  </a>
                ),
              }}
            />
          </p>
          <ul className="grid w-80 grid-cols-2 gap-0 px-0 text-sm text-[#8a99bf] sm:w-96 md:text-base">
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              Javascript
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              React
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              Redux
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              TypeScript
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              Styled Components
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              Tailwind
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              WordPress
            </li>
            <li className="text-white">
              <ArrowRightIcon className="mr-2 inline-block h-4 w-4 p-0 text-[#F8B2D8]" />
              Gatsby
            </li>
          </ul>
        </div>
      </div>
      <div className="picture">
        <img className="mt-20 mb-8 rounded-b-full pl-2 md:max-w-lg 2xl:mt-6" src={me} alt="me" />
      </div>
    </section>
  );
}
